$(document).ready(function(){

	var touch = $('#touch-menu');
	var menu = $('#menu-top-menu');

	$(touch).on('click', function(e) {
		e.preventDefault();
		menu.slideToggle();
	});

	$(window).resize(function(){
		var w = $(window).width();
		if(w > 991 && menu.is(':hidden')) {
			menu.removeAttr('style');
		}
	});

	// First slider
	$("#main-slider").owlCarousel({
		navigation: true,
		slideSpeed: 300,
		paginationSpeed: 400,
		singleItem: true
	});

	// New Products
	$("#new-items-slider").owlCarousel({
		items : 4,
		itemsDesktop : [1000,4],
		itemsDesktopSmall : [900,4],
		itemsTablet: [600,2],
		itemsMobile : false,
		navigation: true,
	});

	// Exlusive Products
	$("#exlusive-items-slider").owlCarousel({
		items : 4,
		itemsDesktop : [1000,4],
		itemsDesktopSmall : [900,4],
		itemsTablet: [600,2],
		itemsMobile : false,
		navigation: true,
	});

	// Related Products
	$("#related-items-slider").owlCarousel({
		items : 4,
		itemsDesktop : [1000,4],
		itemsDesktopSmall : [900,4],
		itemsTablet: [600,2],
		itemsMobile : false,
		navigation: true,
	});

	// Thumbs in single
	$(".thumbs").owlCarousel({
		items : 3,
		itemsDesktop : [1000, 3],
		itemsDesktopSmall : [900, 3],
		itemsTablet: [600,2],
		itemsMobile : false,
		navigation: true,
	});

	$(".c_phone").mask("+7 (999)-999-99-99");
	$(".f_phone").mask("+7 (999)-999-99-99");
	$(".m_phone").mask("+7 (999)-999-99-99");
	$(".o_phone").mask("+7 (999)-999-99-99");
	$(".b_phone").mask("+7 (999)-999-99-99");
	$(".fe_phone").mask("+7 (999)-999-99-99");
	$(".s_phone").mask("+7 (999)-999-99-99");
	$("input[name='phone']").mask("+7 (999)-999-99-99");
	$("#phone").mask("+7 (999)-999-99-99");
	$("#custom-content-form input[name='phone']").mask("+7 (999)-999-99-99");
});

// top search
$( '.search-btn' ).click(function() {
	$('.main-menu-top .search-form').removeClass().addClass('search-form display');
});

$( '.close-search' ).click(function() {
	var is_display = $('.main-menu-top .search-form').attr('class');
	if (is_display == 'search-form display') {
		$('.main-menu-top .search-form').removeClass().addClass('search-form');
	}
});

// left menu
$( '#menu-left li' ).click(function() {

	var is_display = $(this).find('.sub-menu').attr('class');

	if (is_display == 'sub-menu') {
		$(this).find('.sub-menu').removeClass().addClass('sub-menu display');
		$(this).addClass('selected');
	} else {
		$(this).find('.sub-menu').removeClass('sub-menu display').addClass('sub-menu');
		$(this).removeClass('selected');
	}
});

function open_modal(css) {
	$.arcticmodal('close');
	$('#'+css).arcticmodal();
}

function callme() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/callme.php",
		data: $("#callmeform").serialize(),

		beforeSend: function() {
			$('#result').html();
        },

		success: function(html) {
			$('#result').html(html);
			$("#result").empty();
			$("#result").append(html);
		}

    });
}

$(document).on('click', '.js_form-header-offer-btn', function() {
	if($('#form-header-offer input[name="phone"]').val() === '') {
		$('#form-header-offer input[name="phone"]').css({
			'background-color': '#ff6c6c'
		});
		return false;
	}
	$.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/header_offer.php",
		data: $("#form-header-offer").serialize(),
		beforeSend: function() {
			$('.js_form-header-offer-btn').attr('disabled', true);
        },
		success: function(html) {
			$('.js_form-header-offer-btn').remove();
			$('#form-header-offer').empty().html("<p>Заявка отправлена!</p>");
		}
    });
});

function makeorder() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/makeorder.php",
		data: $("#makeorderform").serialize(),

		beforeSend: function() {
			$('#orderesult').html();
        },

		success: function(html) {
			$('#orderesult').html(html);
			$("#orderesult").empty();
			$("#orderesult").append(html);
		}

    });
}

function makecalc() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/makecalc.php",
		data: $("#makecalcform").serialize(),

		beforeSend: function() {
			$('#calcresult').html();
        },

		success: function(html) {
			$('#calcresult').html(html);
			$("#calcresult").empty();
			$("#calcresult").append(html);
		}

    });
}

function bottom_form() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/bottomform.php",
		data: $("#bottomform").serialize(),

		beforeSend: function() {
			$('#bottomresult').html("<style>.bottomform-overlay { display: block!important; }</style>");
        },

		success: function(html) {
			$('#bottomresult').html(html);
			$("#bottomresult").empty();
			$("#bottomresult").append(html);
		}

    });
}

function feed_form() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/feedform.php",
		data: $("#feedform").serialize(),

		beforeSend: function() {
			$('#feedresult').html();
        },

		success: function(html) {
			$('#feedresult').html(html);
			$("#feedresult").empty();
			$("#feedresult").append(html);
		}

    });
}

function slider_form() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/sliderform.php",
		data: $("#slider-form").serialize(),

		beforeSend: function() {
			$('#res-slider-form').html();
        },

		success: function(html) {
			$('#res-slider-form').html(html);
			$("#res-slider-form").empty();
			$("#res-slider-form").append(html);
		}

    });
}

var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/56f2753f24c5a0b345c73d11/default';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();

Dropzone.autoDiscover = false;

$("div#file").dropzone({
	url: "/wp-content/themes/str-tema/inc/upload.php",
	maxFiles: 1,
	maxFilesize: 5,
	addRemoveLinks: true,
	dictRemoveFile: "Убрать",
	dictMaxFilesExceeded: "Превышено кол-во загружаемых файлов!",
	dictInvalidFileType: "Разрешены расширения: word, rar, jpg, png, pdf",
	dictFileTooBig: "Файл слишком большой",
	params: {
		id_m: $('meta[name="csrf-token"]').attr('content')
	},
	acceptedFiles: 'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.rar'
});

$("div#file").click(function() {
	$("#self_project").prop("checked", true);
});

// выбрать по типу
$(".open-filtered-pages").click(function() {
	var is_display = $(".filtered-pages").attr("style");

	if (is_display == 'display: none;') {
		$(".filtered-pages").slideDown(200, function() {
			$(".open-filtered-pages").text("Свернуть");
		});
	} else {
		$(".filtered-pages").slideUp(200, function() {
			$(".open-filtered-pages").text("Выбрать по типу");
		});
	}
});

function formTwoHome() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/formtwohome.php",
		data: $("#form-two-home-form").serialize(),

		beforeSend: function() {
			$("#form-two-home-form").html();
        },

		success: function(html) {
			$('#res-form-two-home-form').html(html);
			$("#res-form-two-home-form").empty();
			$("#res-form-two-home-form").append(html);
		}

    });
}

// get and title from table to modal
function getTitle(title) {
	if(title) {
		$(".ta_title").prop("value", title);
	}
}

function maketableorder() {
    $.ajax({
        type: "POST",
        url: "/wp-content/themes/str-tema/inc/maketableorder.php",
		data: $("#maketableorderform").serialize(),

		beforeSend: function() {
			$('#orderesulttable').html();
        },

		success: function(html) {
			$('#orderesulttable').html(html);
			$("#orderesulttable").empty();
			$("#orderesulttable").append(html);
		}

    });
}

// custom form
$("body").on("click", "#custom-content-form button", function() {

	if(!$("#custom-content-form input[name='phone']").val()) {
		$("#custom-content-form input[name='phone']").css("border-color", "red");
		return false;
	}

	$("#custom-content-form").submit();
});
